import { useState,useEffect } from 'react';
import { DropdownButton, ListGroup, Button, Modal } from 'react-bootstrap';
import tinycolor from 'tinycolor2';
import './User.css';
import { CgLock, CgLockUnlock } from 'react-icons/cg';
import Calculator from './Calculator'; // importer le composant de la "calculette"
import bcrypt from 'bcryptjs';


function User({ onUserNameUpdate,onUserSet, userList, userFirst, unlock, unlockValue, hashDoodle }) {
  const [name, setName] = useState('');
  const [oldname, setOldName] = useState(name);
  const [color, setColor] = useState('');
  const [options, setOptions] = useState({});
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [tabColor, setTabColor] = useState(generateRandomColors(10));
  const [isLocked, setIsLocked] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);
  const [inputCalculator, setInputCalculator] = useState('');
  const [code, setCode] = useState(null);

  const [openDropdownId, setOpenDropdownId] = useState(null);

  const handleToggle = (isOpen, userId) => {
    setOpenDropdownId(isOpen ? userId : null);
  };

  // ...

  const handlePasswordSubmit = async (hashedPassword, password) => {

    if (options && options.hasOwnProperty('hash') && options['hash'] !== "") {
      const isMatch = await bcrypt.compare(password, options['hash']);
      if (isMatch) {
        unlock(true);
        setIsLocked(true);
      }
    } else {
      //création d'un nouveau mdp
      let newOptions = {};
      if (options) {
        newOptions = { ...options };
      }
      // crée une copie de l'objet options
      newOptions.hash = hashedPassword; // modifie la copie
      setCode(password);
      setOptions(newOptions); // définit le nouvel état
      unlock(false);
      setIsLocked(true);
      const newUser = {
        user: selectedUser,
        color: color,
        options: newOptions
      };




      onUserSet(newUser);
    }


    setShowCalculator(false);
  };


  const handleClickVerou = (event) => {
    event.stopPropagation();
    if (isLocked && !unlockValue) {
      setShowCalculator(true);
    } else {
      if (!isLocked && !unlockValue) {
        setShowCalculator(true);
      } else {
        unlock(false);
        setIsLocked(true);
      }

    }

  };

  const handleNameChange = (event) => {
    let newName = event.target.value;

    // Vérifie si le nom d'utilisateur existe déjà dans la liste des utilisateurs

    const userExists = userList.some((user) => user.user === newName);

    // Si l'utilisateur existe déjà, ajoutez un "2" après le nom
    if (userExists) {
      newName += "2";
    }

  
    setName(newName);

  };



  const handleNameModifSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`/api/updateUserNameByHash/${hashDoodle}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userName: oldname, newName: name }),
      });

      if (response.ok) {
        console.log("nom modifié");
        setOpenDropdownId(null);
        const updatedSelectedUser = selectedUser === oldname ? name : selectedUser;
        setSelectedUser(updatedSelectedUser)
        onUserNameUpdate(oldname, name); // Mettre à jour l'état du parent
        setOldName(name); 
      } else {
        const errorData = await response.json();
        console.log(`Failed to update user name: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      console.log('Error updating user name');
    }
  };

  

  const handleColorChange = (color, event) => {
    event.stopPropagation();
    setSelectedColor(tinycolor.mix(color, "white", 90).toString());
    setColor(color);
    const newUser = {
      user: selectedUser,
      color: color
    };
    onUserSet(newUser);
  };

  const stopClic = (event) => {
    event.stopPropagation();
  };

  const randomHex = () => {
    const number = Math.floor(Math.random() * 256);
    return number.toString(16).padStart(2, '0').toUpperCase();
  };

  //ajoud 'un user
  const handleNameSubmit = (event) => {
    event.preventDefault();
    setColor("#" + randomHex() + randomHex() + randomHex());
    setSelectedColor(tinycolor.mix(color, "white", 90).toString());

    const userExists = userList.some((user) => user.user === name);

    // Si l'utilisateur existe déjà, ajoutez un "2" après le nom
    if (!userExists) {
      if (name) {
        const newOptions = {

          hash: ""
        };
        setOptions(newOptions);
        setIsLocked(false);
        unlock(true);


        let newuser = { user: name, color: "#" + randomHex() + randomHex() + randomHex(), options: newOptions }
        onUserSet(newuser);
        handleSelectName(newuser);
      }
    }

    setName("");

  };

  //choix d'un user
  const handleSelectName = (selectedName) => {
    setSelectedUser(selectedName.user);
    setSelectedColor(tinycolor.mix(selectedName.color, "white", 90).toString());
    setOldName(selectedName.user); 
    setOptions(selectedName.options)
    setColor(selectedName.color);
    onUserSet(selectedName);
    if (selectedName.options && selectedName.options.hasOwnProperty('hash') && selectedName.options['hash'] !== "") {
      setIsLocked(true);
    } else {
      if (unlockValue) {
        setIsLocked(true);
      } else {

        setIsLocked(false);

      }

    }

  };

  function generateRandomColors(count) {
    let colors = [];
    const hexValues = '0123456789ABCDEF'; // caractères hexadécimaux

    for (let i = 0; i < count; i++) {
      let color = '#';
      // générer 6 caractères hexadécimaux aléatoires pour chaque couleur
      for (let j = 0; j < 6; j++) {
        color += hexValues[Math.floor(Math.random() * 16)];
      }
      colors.push(color);
    }
    return colors;
  }




  return (
    <div>

      <Modal show={showCalculator} onHide={() => setShowCalculator(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Entrez votre code de vérouillage : {inputCalculator}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Calculator onSubmit={handlePasswordSubmit} onInputChange={setInputCalculator} />
        </Modal.Body>
      </Modal>

      <div className="d-flex flex-column align-items-center">

        {userList.length > 0 ? ((selectedUser === null ? (<span className="mb-1 mt-2"><h2>Choisissez votre nom</h2></span>) : (<span className="mb-1">Visualisez les choix des autres</span>))) : ('')}



      </div>

      <ListGroup>
        { userList && userList.map((user, index) => ( 
           (openDropdownId === null || openDropdownId === user.user) && (
          <ListGroup.Item
            key={user.user}
            onClick={(event) => handleSelectName(user, event)}
            className="list-item"
            style={selectedUser === user.user ? { backgroundColor: `${selectedColor}` } : { backgroundColor: `#FFFFFF` }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{
                backgroundColor: selectedUser === user.user ? color : user.color,

                width: "20px",
                height: "20px",
                borderRadius: "50%",
                marginRight: "10px"
              }}></div>
              <span style={{ fontWeight: "bold" }}>{decodeURIComponent(user.user)}</span>
              <div style={{ marginLeft: "auto", display: "flex", alignItems: "left" }}>

              </div>

              {(selectedUser === user.user) && (selectedUser === userFirst) ? (

                <>
                  {code ? (<span style={{ fontSize: '0.7rem' }}>le code {code} est définitif</span>) : (null)}
                  {isLocked && !unlockValue ? (<Button variant="outline-secondary" onClick={(event) => handleClickVerou(event)} className={`mx-2 btn-outline-danger`}>
                    <div><CgLock style={{ color: 'red' }} /> Dévérouillez</div>
                  </Button>) : (isLocked && !unlockValue ? null :
                    <DropdownButton   onToggle={(isOpen) => handleToggle(isOpen, user.user)}  variant="outline-secondary" id="dropdown-colors" title="Options" onClick={(event) => stopClic(event)}>


                      <div style={{ width: "350px" }}>
                        <div class="card-body">
                          <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0">Vérouillez vos choix</h5>
                            <button type="button" className="btn mx-3 btn-outline-success" onClick={(event) => handleClickVerou(event)}>
                              <div><CgLockUnlock style={{ color: 'lightgreen' }} /> Vérouiller </div>
                            </button>
                          </div>
                          <hr />
                          <div className="d-flex align-items-center">
                            <h5 className="card-title mt-3">Changer votre couleur</h5>
                            <div className="d-flex flex-wrap" style={{ width: "150px" }}>
                              {tabColor.map((color) => (
                                <div key={color} onClick={(event) => handleColorChange(color, event)} style={{ backgroundColor: color, width: "20px", height: "20px", margin: "5px", borderRadius: "50%", cursor: "pointer" }}></div>
                              ))}
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex align-items-center">
                            <h5 className="card-title mt-3">Modifier votre nom</h5>
                            </div>
                            <div className="d-flex align-items-center">
                             
                                <div className="input-group" style={{ maxWidth: "500px" }}>
                                  <input type="text" className="form-control" value={name} onChange={handleNameChange} placeholder='Votre Nom : ' />
                                  <Button variant="primary" type="submit" onClick={(event) => handleNameModifSubmit( event)}> &nbsp;&nbsp; modifier &nbsp;&nbsp;</Button>
                                </div>
                              
                           
                          </div>
                        </div>

                      </div>


                    </DropdownButton>
                  


                  )}





                </>


              ) : (Object.entries(user?.options || {}).map(([key, value], i) => ( //l'opérateur de chaînage optionnel (?.). Cela permet d'accéder en toute sécurité aux propriétés imbriquées d'un objet
                <div key={i}>
                  {key === "hash" && value !== "" ? (<span style={{ fontSize: '0.5rem' }}>🔐 </span>) //{value} pour afficher le hash
                    : null}
                </div>
              )))}

            </div>
          </ListGroup.Item>)
        ))}
         { openDropdownId === null && (
        <ListGroup.Item >
          <div style={{ display: "flex", alignItems: "left" }}>
            <div className="row">
              <div className="col-md-6" style={{ display: "flex", alignItems: "left" }}>
                <span style={{ marginTop: "3px", marginBottom: "10px" }}>Si votre nom n'est pas dans la liste  ajoutez le </span>
              </div>
              <div className="col-md-6">
                <form onSubmit={handleNameSubmit} className="form">
                  <div className="input-group" style={{ maxWidth: "500px" }}>
                    <input type="text" className="form-control" value={name} onChange={handleNameChange} placeholder='Votre Nom : ' />
                    <Button variant="primary" type="submit"> &nbsp;&nbsp; ajoutez &nbsp;&nbsp;</Button>
                  </div>
                </form>



              </div>  </div>   </div>

        </ListGroup.Item>)}
      </ListGroup>

    </div>
  );
}

export default User;
