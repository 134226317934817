import React, { useState, useEffect } from 'react';
import './CalendarList.css';
import { ListGroup, Button } from 'react-bootstrap';


const CalendarList = ({ currentCalendarNumber, currentCalendarName }) => {
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {
    const storedCalendars = JSON.parse(localStorage.getItem('visitedCalendars'));
    if (storedCalendars) {
      setCalendars(storedCalendars);
    }
  }, []);

  const handleCalendarDelete = (event, calendarNumber) => {
    event.stopPropagation();
    event.preventDefault();
    const updatedCalendars = calendars.filter(
      (calendar) => calendar.number !== calendarNumber
    );
    setCalendars(updatedCalendars);
    localStorage.setItem('visitedCalendars', JSON.stringify(updatedCalendars));

  };

  useEffect(() => {
    if (currentCalendarNumber !== null) {
      addCalendar(currentCalendarNumber, currentCalendarName);
    }
  }, [currentCalendarNumber]);


  const addCalendar = (calendarNumber, calendarName) => {
    if (calendarNumber !== "" && calendarNumber !== undefined) {


      // Récupère les calendriers existants du localStorage
      const storedCalendars = JSON.parse(localStorage.getItem('visitedCalendars')) || [];

      // Vérifie si le calendrier avec le numéro donné existe déjà
      const calendarExists = storedCalendars.some(
        (calendar) => calendar.number === calendarNumber
      );

      if (!calendarExists) {
        const newCalendar = { number: calendarNumber, name: calendarName };

        // Ajoute le nouveau calendrier aux calendriers existants
        const updatedCalendars = [...storedCalendars, newCalendar];

        // Met à jour l'état local et le localStorage
        setCalendars(updatedCalendars);
        localStorage.setItem('visitedCalendars', JSON.stringify(updatedCalendars));
      } else {
        // Si le calendrier existe déjà, met à jour simplement l'état local
        setCalendars(storedCalendars);
      }
    }
  };


  return (
    <div key={"CalendarList0"} className='CalendarList0'>
      {calendars.length > 0 ? (
        <h3 className="mb-3" style={{ marginTop: "10px" }}>Vos autres   <img width="20px" src="img/icon.png" alt="logo doolight" style={{ marginTop: '-6px' }} /> Doolight en cours</h3>
      ) : null}
      <ListGroup>
        {calendars.map((calendar) => (
         <ListGroup.Item
         key={calendar.number}
         className="d-flex align-items-center justify-content-between"
         onClick={() => window.location.href = `${window.location.origin}/?cal=${calendar.number}`}
         style={{ cursor: "pointer" }}
       >
          <div style={{display: 'flex', alignItems: 'center'}}>
    <img width="20px" src="img/icon.png" alt="logo doolight" style={{ marginRight: '10px' }} />
    <span>{decodeURIComponent(calendar.name)}</span>
  </div>
  <button type="button" style={{
    backgroundColor: 'white',
    color: 'red',
    border: '1px solid rgba(255, 0, 0, 0.32)',
    borderRadius: '10%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    padding: '0',
    lineHeight: '0px',
    fontSize: '20px',
    display: 'inline-block'
  }} onClick={(event) => handleCalendarDelete(event, calendar.number)}>&times;</button>
</ListGroup.Item>
        ))}
        {currentCalendarNumber !== "" && (
          <ListGroup.Item
            key={0}
            className="d-flex align-items-center"
            onClick={() => window.location.href = `${window.location.origin}/`}
          >
            <button key="button0addnew" type="button" className="btn btn-success">Creer un autre Doolight</button>
          </ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );



};

export default CalendarList;