import React, { useEffect, useState } from 'react';
import ToggleSwitch from './ToggleSwitch';

const OptionsManager = ({ initialOptions, onOptionsChange }) => {
    const [options, setOptions] = useState(initialOptions);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    const handleToggleChange = (optionKey) => (event) => {
        const updatedOptions = { ...options, [optionKey]: event.target.checked };
        setOptions(updatedOptions);
        onOptionsChange(updatedOptions);
    };

    const handleTextChange = (optionKey) => (event) => {
        const updatedOptions = { ...options, [optionKey]: event.target.value };
        setOptions(updatedOptions);
        onOptionsChange(updatedOptions);
    };

    return (
        <div className="container mt-2">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                    <h4>Options Premiums</h4>
                    <img src="img/premium.png" alt="Icône" style={{ width: "30px", marginLeft: "10px", marginTop: "-10px" }} />
                </div>
                <button className="btn btn-outline-secondary" onClick={() => setIsVisible(!isVisible)}>
                    ...
                </button>
            </div>
            {isVisible && Object.keys(options).map((optionKey) => {
                const value = options[optionKey];
                return (
                    <div className="d-flex justify-content-between align-items-center mb-2" key={optionKey}>
                        <div className="text-nowrap">
                            <span>{optionKey}</span>
                        </div>
                        {typeof value === 'boolean' ? (
                            <div className="flex-shrink-0">
                                <ToggleSwitch
                                    id={optionKey}
                                    initialMode={value}
                                    onToggle={handleToggleChange(optionKey)}
                                />
                            </div>
                        ) : (
                            <div className="w-50">
                                {optionKey === 'Description' ? (
                                    <textarea
                                        className="form-control"
                                        value={value}
                                        onChange={handleTextChange(optionKey)}
                                        rows="4"
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value}
                                        onChange={handleTextChange(optionKey)}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
            {isVisible && Object.keys(options).length === 0 ? (
                <p>Pour bénéficier de fonctionalités plus pratiques vous pouvez essayer nos options beta premiums en ajoutant cet emoji 🍁 à la fin de votre prenom </p>
            ) : (
                null
            )}

        </div>
    );
};

export default OptionsManager;
