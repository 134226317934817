import React, { useState } from 'react';
import { Button, Container, Row, Col, Alert } from 'react-bootstrap';
import bcrypt from 'bcryptjs';

const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '@', '!', '$', '&'];

function Calculator({ onSubmit, onInputChange }) {
    const [input, setInput] = useState('');
    const [error, setError] = useState('');

    const handleClick = async (value) => {
        // Votre code précédent ...
        const updatedInput = input + value;

        if (!isNaN(parseInt(value)) && updatedInput.length <= 4) {
            setInput(updatedInput);
            onInputChange(updatedInput);
        } else if (isNaN(parseInt(value)) && updatedInput.length === 5) {
            const salt = await bcrypt.genSalt(10);
            const hashedInput = await bcrypt.hash(updatedInput, salt);
            setInput('');
            onInputChange('');
            onSubmit(hashedInput,updatedInput);
            //
            
        } else {
            if (updatedInput.length > 5) {
                setError('Le code doit contenir 4 chiffres et un symbole seulement');
                setInput(input); // Reset l'input à sa valeur précédente
            } else if (!isNaN(parseInt(value)) && updatedInput.length < 4) {
                setInput(updatedInput);
                onInputChange(updatedInput);
            } else {
                setError('Le code doit contenir 4 chiffres avant le symbole');
                setInput(input); // Reset l'input à sa valeur précédente
            }
        }
    };

    const handleDelete = () => {
        let varin = input.slice(0, -1);
        setInput(varin);
        onInputChange(varin);
        setError('');
    };

    return (
        <Container style={{ maxWidth: '320px', margin: '0 auto', border: '1px solid #000', padding: '20px', borderRadius: '10px' }}>

            <div className="mb-4" style={{ textAlign: 'center' }}>
                {keys.slice(0, 10).map((key, idx) => (
                    <Button key={idx} onClick={() => handleClick(key)} className="m-1 p-3">{key}</Button>
                ))}
            </div>
            <div className="mb-4" style={{ textAlign: 'center' }}>
                {keys.slice(10).map((key, idx) => (
                    <Button key={idx} onClick={() => handleClick(key)} className="m-1 p-3">{key}</Button>
                ))}
                <Button onClick={handleDelete} variant="light"  className="m-1 p-3" style={{ border: "1px solid grey" }}>🔙</Button>
            </div>

            {error && (
                <Row>
                    <Col><Alert variant="danger">{error}</Alert></Col>
                </Row>
            )}

        </Container>
    );
}

export default Calculator;
