import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*
 Cette ligne crée un point d'entrée (ou racine) pour votre application
 React en utilisant la méthode createRoot() de la bibliothèque React DOM. 
 Elle prend en paramètre l'élément HTML avec l'ID "root" où l'application 
 sera rendue
*/
const root = ReactDOM.createRoot(document.getElementById('root'));

/*
 Cette ligne rend votre composant App à l'intérieur du point d'entrée créé 
 précédemment. Le composant est enveloppé dans un composant React.StrictMode, 
 qui permet de détecter les problèmes potentiels dans votre code et d'émettre 
 des avertissements à l'avance.
*/
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

/*Cette ligne appelle la fonction reportWebVitals (qui se trouve dans le
  fichier  reportWebVitals.js) pour mesurer et signaler les performances de 
  otre application. Vous pouvez personnaliser cette fonction pour envoyer les
  résultats de mesure à une API ou à un outil de suivi des performances de
  votre choix.
 */
reportWebVitals();
