import React from 'react';
import tinycolor from 'tinycolor2';
import './UserDispo.css';
import { useState } from 'react';


const UserDispo = ({ users, activeUser, maxUser , anonymous}) => {

  const colorNumber = tinycolor.mix('#cfe2ff', "white", 80).toString();
  const [showUsers, setShowUsers] = useState(false);
  const neutralColor = "#CCCCCC"; // Couleur neutre pour l'anonymat

  const MAX_USERS_TO_DISPLAY = 3;
  // trier les utilisateurs en fonction de l'utilisateur actif
  const sortedUsers = users.sort((a, b) => {
    if (a.user === activeUser) {
      return -1; // a est l'utilisateur actif, donc le mettre en premier
    } else if (b.user === activeUser) {
      return 1; // b est l'utilisateur actif, donc le mettre en premier
    } else {
      return 0; // les deux noms sont équivalents
    }
  });

  users.map((userObj) => {
    userObj.color2 = tinycolor.mix(userObj.color, "white", 90).toString();
    return userObj;
  });


  const handleClick = (e) => {
    e.stopPropagation();
    setShowUsers(true);
  };

  const handleClickDeselect = (e) => {
    e.stopPropagation();
    setShowUsers(false);
  };

  const handleMouseLeave = () => {
    setShowUsers(false);
  };

  const usersToDisplay = sortedUsers.length < 1
    ? [{ user: "", color: "rgba(207, 226, 255, 0)", color2: "rgba(207, 226, 255, 0)", isFictitious: true }]
    : sortedUsers.slice(0, MAX_USERS_TO_DISPLAY);

  // si le nombre d'utilisateurs est supérieur à MAX_NAMES_TO_DISPLAY, affichez le nombre d'utilisateurs restants en plus des utilisateurs visibles.
  const additionalUsersCount = sortedUsers.length - 0;

  return (
    <div style={{ display: "flex" }}>
      {usersToDisplay.map((user, index) => (
        <div
          key={"u" + index + user.user}
          style={{
            borderRadius: "50%",
            width: "32px",
            height: "32px",
            border: user.user ? `2px solid ${anonymous ? "#000000" : user.color}` : "none",
            color: user.user === activeUser ? "#FFFFFF" : "#000000",
            backgroundColor: user.user 
            ? (anonymous 
                ? neutralColor  // Couleur neutre si anonyme et utilisateur présent
                : (user.user === activeUser ? `${user.color}` : `${user.color2}`))  // Couleur normale si non anonyme
            : "transparent",  // Pas de couleur si pas d'utilisateur
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "-13px",
            zIndex: MAX_USERS_TO_DISPLAY - index,
            position: "relative",
          }}
        >
       <span style={{ fontWeight: "bold" }}>
  {user.isFictitious || !user.user ? " " : (anonymous ? "☝🏻" : decodeURIComponent(user.user).charAt(0).toUpperCase() + decodeURIComponent(user.user).charAt(1).toLowerCase())}
</span>
        </div>
      ))}




      {additionalUsersCount > 0 ? (
        <div
          key={"v1"}
          
          onClick={handleClick}
          style={{
            backgroundImage: `url(${additionalUsersCount > 0 ? (maxUser === additionalUsersCount ? "t2.png" : "t1.png") : "t1.png"})`,
            cursor: `zoom-in`,
            height:'100%'
          }}
          className='triangle showUser'
        >
          <span
            style={{
              backgroundColor: `transparent`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold"
            }}
          >
            {additionalUsersCount}
          </span>
        </div>
      ) : (null)}
      <span
        style={{
          backgroundColor: `transparent`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold"
        }}
      >

      </span>

      {showUsers && (
        <div style={{
          position: 'absolute',
          width: '100%',
          top: '-11px',
          left: '0',
          background: '#fff',
          borderTop: '1px solid #ddd',
          borderBottom: '1px solid #ddd',
          paddingTop: '12px',
          paddingBottom: '13px',
          paddingRight: '5px',
          paddingLeft: '5px',
          marginTop: '10px',
          marginRight: '-1px',
          zIndex: 1000,

        }} onMouseLeave={handleMouseLeave} onClick={handleClickDeselect} className='showUser' >
          {anonymous ? "Utilisateurs anonymes" : users.map(user => user.user).join(', ')}
        </div>
      )}

    </div>
  );
};

export default UserDispo;