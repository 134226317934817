import React, { useState } from "react";
import { FaFacebookMessenger, FaShare, FaEnvelope, FaSnapchat, FaWhatsapp, FaCopy , FaTwitter, FaDiscord } from "react-icons/fa";
import { Button} from "react-bootstrap";

const ShareButton = ({ link,calName }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 1000);
      })
      .catch(() => setCopySuccess(false));
  };

  const handleShare = async (shareMethod) => {
    
    let nav = true;
    if(navigator.share && typeof navigator.share === 'function'){
      console.log("coucou");
      nav = true;
    }else{
      nav = false;
      console.log("pascoucou");
    }

    try {
      await navigator.share({
        title: calName,
        text: "Merci de renseigner vos dispos sur ce dooLight",
        url: link
      });
    } catch (error) {
      console.error("Error sharing:", error);
      if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
        console.log('L\'utilisateur a annulé l\'action de partage.');
      } else{
        emailShare(error);
      }
     
     
     
      // Fallback to traditional sharing methods
      switch (shareMethod) {
        case "facebook":
          //facebookMessengerShare();
          break;
        case "sms":
          //smsShare();
          break;
        case "email":
          //emailShare();
          break;
        case "snapchat":
          //snapchatShare();
          break;
        case "whatsapp":
          //whatsappShare();
          break;
         case "twitter":
           // twitterShare();
            break;
            case "discord":
             //   discordShare();
                break;
        default:
          console.error("Invalid share method:", shareMethod);
      }
    }
  };

  const twitterShare = () => {
    const url = `twitter://share?link=${link}`;
    window.location.href = url;
  };

  const discordShare = () => {
    const url = `discord://share?link=${link}`;
    window.location.href = url;
  };

  const facebookMessengerShare = () => {
    const url = `fb-messenger://share?link=${link}`;
    window.location.href = url;
  };

  const smsShare = () => {
    const url = `sms:&body=${link}`;
    window.location.href = url;
  };

  const emailShare = (error) => {
    const subject = "Evenement Doolight : "+calName+"error : "+error;
    const body = `Merci de renseigner vos dispos sur ce lien : ${link}`;
    const url = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = url;
  };

  const snapchatShare = () => {
    const url = `snapchat://share?url=${link}`;
    window.location.href = url;
  };

  const whatsappShare = () => {
    const url = `whatsapp://send?text=${link}`;
    window.location.href = url;
  };

  return (
    <div className="col text-center mt-3">
    
      <Button className="btn btn-primary m-1" onClick={() => handleShare("facebook")}><FaShare /> Partager </Button>
     
      <Button className={copySuccess ? "btn btn-success m-1!" : "btn btn-secondary m-1"}  onClick={handleCopyLink}>
        <FaCopy />
        {copySuccess ? "Copie ok !!" : " Copier votre lien Doolight"}
      </Button>
   
  </div>
  );
};

export default ShareButton;
