import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import './Calendrier.css';
import LogError from './LogError';
import User from './User';
import Share from './Share';
import { FaTimes, FaPlus } from 'react-icons/fa';
import UserDispo from "./UserDispo";
import HeuresComponent from './HeuresComponent';
import CalendarList from './CalendarList';
import ToggleSwitch from './ToggleSwitch';
import OptionsManager from './OptionsManager';
import Banner from './Banner';
import { First } from 'react-bootstrap/esm/PageItem';




function Calendrier() {
  const [debugMessages, setDebugMessages] = useState('');
  const inputRef = useRef(null);
  const inputHeureRef = useRef(null);
  const [editingColumnIndex, setEditingColumnIndex] = useState(-1);
  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [date, setDate] = useState(new Date());
  const [jours, setJours] = useState(['lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.', 'dim.']);
  const joursRef = useRef(jours);
  const [heures, setHeures] = useState(['Matin', 'Midi', 'Aprem', 'Soir']);
  const heuresRef = useRef(heures);
  const [casesParSemaine, setCasesParSemaine] = useState({});
  const casesParSemaineRef = useRef(casesParSemaine);
  const [sondage, setSondage] = useState(false);
  const sondageRef = useRef(sondage);



  const [userDispo, setUserDispo] = useState({});
  const [activeCell, setActiveCell] = useState(null);

  const currentAction = useRef(null);
  const [currentUser, setCurrentUser] = useState("");
  const [admintUser, setAdminUser] = useState("");
  const [maxUser, setMaxUser] = useState(2);
  const [currentColor, setCurrentColor] = useState("");
  const [calendarName, setCalendarName] = useState('');
  const [calHash, setCalHash] = useState('');
  const [inputVisible, setInputVisible] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [isTactil, setTactil] = useState(false);
  const [isInit, setInit] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [calendriers, setCalendriers] = useState([]);
  const isMouseDown = useRef(false);
  const [relaodUserDispo, setRelaodUserDispo] = useState(true);
  const isTouchActive = useRef(false);
  const [listUser, setListUser] = useState([]);
  const [morceauxJours, setMorceauxJours] = useState([]);
  const [needSave, setNeedSave] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [userFirst, setUserFirst] = useState(null);
  const [options, setOptions] = useState({});
  const [userOptions, setUserOptions] = useState({});
  const [tempUnLock, setTempUnLock] = useState(false);

  function useDebounce(callback, delay) {
    const timer = useRef(null);

    const debouncedCallback = useCallback(() => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        callback();
      }, delay);
    }, [callback, delay]);

    const cancel = () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };

    return [debouncedCallback, cancel];
  }


  const saveLater = useCallback(() => {
    if (currentUser === userFirst) {

      save(true, currentColor, userOptions, false, null, true);
    }


  }, [casesParSemaine]);



  const [debouncedSave, cancelSave] = useDebounce(saveLater, 3000);

  const handleOptionsChange = (newOptions) => {
    setOptions(newOptions);
  };

  const updateList = useCallback((updateFunction) => {

    setListUser(() => updateFunction);

  }, []);

  const handleEditModeToggle = () => {

    if (editMode) {
      save(true, currentColor, userOptions, false, null, false);
    }
    setEditMode(!editMode);
    setNeedSave(true);

  };

  function diviserEnSousTableaux(tableau, taille) {
    const resultat = [];
    for (let i = 0; i < tableau.length; i += taille) {
      resultat.push(tableau.slice(i, i + taille));
    }
    return resultat;
  }

  const handleCalendarNameChange = (event) => {
    setCalendarName(event.target.value);
  };

  const handleCalendarNameSubmit = (e) => {
    e.preventDefault();
    const hash = generateHash();
    const newUrl = `${window.location.origin}/?cal=${hash}`;
    setCalHash(hash);
    window.history.pushState({}, '', newUrl);

    // Récupération du nom du calendrier depuis l'input
    const calendarName = e.target.elements.calendrierName.value;
    setCalendarName(calendarName);
    setInputVisible(false);
  };

  const addDebugMessage = (message) => {
    setDebugMessages(message);
  };

  const handleUserNameUpdate = (oldName, newName) => {
    // Mise à jour de listUser
    /*const updatedListUser = listUser.map(user => {
      if (user.user === oldName) {
        return { ...user, user: newName };
      } else {
        return user;
      }
    });*/


    // Forcer la mise à jour de currentUser et userFirst
    const updatedCurrentUser = currentUser === oldName ? newName : currentUser;
    const updatedUserFirst = userFirst === oldName ? newName : userFirst;
    const AdminUser = admintUser === oldName ? newName : admintUser;

    // Mise à jour de calendriers
    const updatedCalendriers = calendriers.map(calendar => {
      if (calendar.user === oldName) {
        return { ...calendar, user: newName };
      } else {
        return calendar;
      }
    });



    //setListUser(updatedListUser);
    setCalendriers(updatedCalendriers);
    setCurrentUser(updatedCurrentUser);
    setUserFirst(updatedUserFirst);
    setAdminUser(AdminUser);


  };

  const handlelock = (lock) => {
    setTempUnLock(lock)
  }

  const handleUserSet = (selectedUser) => {

    let forceSaveNewUser = false;

    //sauvegarde du calendier de l'ancien user :
    const currentUserCalendar = calendriers.find(cal => cal.user === currentUser);

    // Mettre à jour les cases sélectionnées pour chaque semaine    
    if (currentUserCalendar) {

      currentUserCalendar.weeks.forEach(week => {
        week.selectedCases = casesParSemaine[week.week];
      });

      if (currentColor !== "") {
        currentUserCalendar.userColor = currentColor;
      } else {
        currentUserCalendar.userColor = selectedUser.color;
      }

      if (selectedUser.user === currentUser) {
        currentUserCalendar.userColor = selectedUser.color;;
      }

    }

    console.log("C handleUserSet");
    setListUser(listUser.map(item => {
      if (item.user === currentUser) {
        // Si c'est l'utilisateur dont les options doivent être mises à jour,
        // créer une nouvelle version de cet objet avec les nouvelles options
        return { ...item, options: selectedUser.options, userOptions: selectedUser.options };
      } else {
        // Sinon, laisser l'objet tel quel
        return item;
      }
    }));

    setCalendriers(
      calendriers.map(cal => {
        if (cal.user === currentUser) {
          return currentUserCalendar;
        } else {
          return cal;
        }
      })
    );


    let newUserOptions = { ...userOptions };
    const newUserOptionsPourLaCreation = { ...userOptions };


    const selectedUserData = calendriers.find((theuser) => theuser.user === selectedUser.user);
    if (selectedUserData) {

      let { user, color, options } = selectedUserData;

      // mettre à jour la couleur avec la nouvelle couleur sélectionnée dans le composant User
      console.log("le user :");
      console.log(user);
      setCurrentUser(user);

      if (userFirst === null) {
        setUserFirst(user);

      }

      setCurrentColor(selectedUser.color);


      const updatedCalendriers = calendriers.map((cal) => {
        if (cal.user === user) {

          return {
            ...cal,
            color: selectedUser.color,// mettre à jour la couleur dans la liste des calendriers
            userOptions: selectedUser.options,
            options: selectedUser.options
          };
        }
        return cal;
      });


      setCalendriers(updatedCalendriers); // mettre à jour l'état avec la nouvelle liste de calendriers mise à jour
      const casesParSemaine = {};
      const userCalendar = updatedCalendriers.find((cal) => cal.user === user);
      if (userCalendar) {

        userCalendar.weeks.forEach((week) => {
          casesParSemaine[week.week] = week.selectedCases;
        });
        setCasesParSemaine(casesParSemaine);
      }
      if (selectedUser.color !== color) {
        setNeedSave(true);

      }

      if (selectedUser.options && selectedUser.options.hasOwnProperty("hash")) {
        // crée une copie de l'objet options


        if (selectedUser.user === currentUser || currentUser === "") {
          if (userCalendar.userOptions.hash !== "") {
            newUserOptions.hash = userCalendar.userOptions.hash;
          } else {
            newUserOptions.hash = selectedUser.options.hash;
          }

          setUserOptions(newUserOptions);
        } else {
          newUserOptions = userOptions;
        }



        // définit le nouvel état
      }




    } else { //user non trouvé dans le calendrier c'est une création 

      setCurrentUser(selectedUser.user);
      setCurrentColor(selectedUser.color);
      setUserFirst(selectedUser.user);

      const newCalendrier = {
        user: selectedUser.user,
        userColor: selectedUser.color,
        userOptions: selectedUser.options,
        options: selectedUser.options,
        weeks: [{ "week": getDebutSemaine(date), "selectedCases": [] }]
      };

      forceSaveNewUser = true;

      setCalendriers([...calendriers, newCalendrier]);

      const casesParSemaine = {};
      setCasesParSemaine(casesParSemaine);

      newUserOptionsPourLaCreation.hash = "";
      setUserOptions(newUserOptionsPourLaCreation);
      setTempUnLock(false);

      setNeedSave(true);

    }

    //si il n'y a aucun user encore il faut les droits d'amis 
    if (calendriers.length === 0) {
      // Il y a au moins un utilisateur dans le tableau
      setAdminUser(selectedUser.user);
    }





    if (currentColor !== "" && (currentUser !== selectedUser.user)) {
      save(true, currentColor, newUserOptions, forceSaveNewUser, null, false);
    } else {
      if (Object.keys(newUserOptions).length !== 0) {
        save(true, selectedUser.color, newUserOptions, forceSaveNewUser, null, false);
      } else {
        save(true, selectedUser.color, newUserOptionsPourLaCreation, forceSaveNewUser, selectedUser, false);
      }

    }

  };

  const incrementerSemaine = () => {
    if (date === "sondage") {
      setDate(new Date());
    }
    setDate(new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000));
    save(needSave, currentColor, userOptions, false, null, false);
  };

  const getDebutSemaine = (date) => {
    if (date === "sondage") {
      return "sondage"
    }
    const jour = date.getDay();
    const debutSemaine = new Date(date.getTime() - (jour - 1) * 24 * 60 * 60 * 1000); // date de début de semaine
    if (sondage) {
      return "sondage"
    } else {
      return debutSemaine.toLocaleDateString();
    }

  };



  const getFinSemaine = (date) => {
    if (date === "sondage") {
      return "sondage"
    }
    const debutSemaine = new Date(date.getTime() - (date.getDay() - 1) * 24 * 60 * 60 * 1000);
    const finSemaine = new Date(debutSemaine.getTime() + 6 * 24 * 60 * 60 * 1000);
    return finSemaine.toLocaleDateString();
  };

  const decrementerSemaine = () => {

    const nouvelleDate = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    const dateLimite = new Date();
    dateLimite.setDate(dateLimite.getDate() - 8);

    if (nouvelleDate < dateLimite) {
      // Empêcher la modification si la nouvelle date est antérieure à la date limite
      return;
    }

    setDate(nouvelleDate);
    save(needSave, currentColor, userOptions, false, null, false);
  };

  const handleMouseDown = (jour, heure) => {
    if (!isTactil) {
      isMouseDown.current = true;
      handleClick(jour, heure);

    }

  };

  const handleMouseUp = () => {
    if (!isTactil) {
      isMouseDown.current = false;
    }
  };



  const handleMouseOver = (jour, heure) => {
    if (!isTactil && isMouseDown.current) {
      handleClick(jour, heure);
    }

  };

  const handleTouchStart = (e, jour, heure) => {
    isTouchActive.current = true;
    //e.preventDefault(); // Empêcher le défilement de la page
    //setTactil(true);
    //handleClick(jour, heure);


  };

  const handleTouchMove = (e) => {

    /* if (isTouchActive.current) {
 
       const touch = e.touches[0];
       const target = document.elementFromPoint(touch.clientX, touch.clientY);
       const cellId = target && target.dataset.cellId;
       if (cellId && cellId !== lastTouchedCell.current) {
         lastTouchedCell.current = cellId;
         const [jour, heure] = cellId.split('-');
 
         //handleClick(jour, heure);
       }
     }*/
  };

  const handleTouchEnd = (e) => {

    isTouchActive.current = false;
    //e.preventScroll();

  };

  const getCases = () => {
    const debutSemaine = getDebutSemaine(date);
    return casesParSemaine[debutSemaine] || [];
  };

  const handleClick = (jour, heure) => {

    let access = true;

    // si il y a un hash et que je ne suis pas temporairement débloqué
    if (userOptions.hasOwnProperty('hash') && !tempUnLock) {
      // Faire quelque chose si 'hash' existe
      if (userOptions['hash'] !== "") {
        access = false;
      }

    }

    if (userFirst === currentUser && access) {
      setNeedSave(true);

      setActiveCell({ jour, heure });

      const nouvelleCase = `${jour}-${heure}`;
      const semaine = getDebutSemaine(date);
      const casesSelectionnees = casesParSemaine[semaine] || [];
      const isSelected = casesSelectionnees.includes(nouvelleCase);




      /*if (options["1 seul choix par case"]) {
        // Vérifier si la case a déjà été sélectionnée par un autre utilisateur pour le même jour, heure et semaine
        const isCaseAlreadySelected = Object.values(casesParSemaine).some((cases) =>
          cases.some((c) => c === nouvelleCase && c !== `${jour}-${heure}-${currentUser}`)
        );

        if (isCaseAlreadySelected) {
          // La case est déjà sélectionnée par un autre utilisateur, ne pas l'ajouter
          return;
        }
      }*/


      if (currentAction.current === null) {
        currentAction.current = isSelected ? 'remove' : 'add';
      }

      if (
        (currentAction.current === 'add' && !isSelected) ||
        (currentAction.current === 'remove' && isSelected)
      ) {

        let caseTemp = casesSelectionnees;

        let AjoutOk = true;
        //on vérifi si dans les autres case des autres selectionné il y a quelqu'un sinon message d'erreur.
        if (options["1 seul choix par case"]) {
          const otherUsers = [];

          // Parcourir tous les calendriers
          calendriers.forEach((calendar) => {
            // Exclure le calendrier de l'utilisateur actuel
            if (calendar.user !== currentUser) {
              // Rechercher la semaine en cours dans le calendrier
              const selectedWeek = calendar.weeks.find((week) => week.week === semaine);

              if (selectedWeek) {
                // Vérifier si la case sélectionnée existe dans les autres calendriers
                const isCaseSelected = selectedWeek.selectedCases.includes(nouvelleCase);

                if (isCaseSelected) {
                  // Ajouter l'utilisateur correspondant à la liste des autres utilisateurs ayant sélectionné la même case
                  otherUsers.push(calendar.user);
                }
              }
            }
          });

          if (otherUsers.length > 0) {
            // Afficher un message d'erreur indiquant les autres utilisateurs ayant déjà sélectionné cette case
            const errorMessage = `La case ${nouvelleCase} a déjà été sélectionnée par les utilisateurs suivants : ${otherUsers.join(", ")}`;

            setDebugMessages(errorMessage);
            AjoutOk = false;

            // Autres actions à prendre en cas d'erreur, par exemple afficher une notification à l'utilisateur
          }
        }
        if (AjoutOk) {
          if (options["1 seul choix par user"]) {

            const userCalendarIndex = calendriers.findIndex((calendar) => calendar.user === currentUser);

            if (userCalendarIndex !== -1) {
              // Récupérez le calendrier de l'utilisateur actuel
              const userCalendar = calendriers[userCalendarIndex];

              // Recherchez la semaine en cours dans le calendrier de l'utilisateur
              const selectedWeek = userCalendar.weeks.find((week) => week.week === semaine);

              if (selectedWeek) {
                // Videz les selectedCases pour la semaine en cours
                selectedWeek.selectedCases = [];
              }

              // Mettez à jour le tableau Calendriers avec le calendrier modifié
              calendriers[userCalendarIndex] = userCalendar;
            }

            heures.forEach((h) => {
              jours.forEach((j) => {
                const cellId = `${j}-${h}`;
                userDispo[cellId] = [];
                getAvailableOtherUsers(j, h, calendriers, currentUser);
              });
            });
            setUserDispo(userDispo);
            setCasesParSemaine({
              ...casesParSemaine,
              [semaine]: [nouvelleCase],
            });
          } else {
            setCasesParSemaine({
              ...casesParSemaine,
              [semaine]: isSelected
                ? caseTemp.filter((c) => c !== nouvelleCase)
                : [...caseTemp, nouvelleCase],
            });
          }
        }







      }

      if (!isMouseDown.current && !isSelected && casesSelectionnees[0] === nouvelleCase) {
        setCasesParSemaine({
          ...casesParSemaine,
          [semaine]: [...casesSelectionnees, nouvelleCase],
        });
      }
      casesParSemaineRef.current = casesParSemaine;
      joursRef.current = jours;
      heuresRef.current = heures;
      sondageRef.current = sondage;
      debouncedSave();

    } else {
      let chaine
      if (!access && userFirst === currentUser) {
        chaine = `<div>Vous ne pouvez pas modifier les choix de ${currentUser} son calendrier est vérouillé </div>`;
      } else {
        chaine = `<div>${userFirst} ne peut pas modifier les choix de ${currentUser} <p><a href=${window.location.href}>si vous n'êtes pas ${userFirst} cliquez ici </a></p></div>`;

      }
      setDebugMessages(chaine);

    }




  };


  const handleColumnClick = (jour, index) => {

    let access = true;

    // si il y a un hash et que je ne suis pas temporairement débloqué
    if (userOptions.hasOwnProperty('hash') && !tempUnLock) {
      // Faire quelque chose si 'hash' existe
      if (userOptions['hash'] !== "") {
        access = false;
      }

    }
    if (editMode) {
      setEditingColumnIndex(index);
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 0);
      access = true;
    } else {
      if (userFirst === currentUser && access && !options["1 seul choix par user"] && !options["1 seul choix par case"]) {
        setRelaodUserDispo(true);
        const debutSemaine = getDebutSemaine(date);
        const casesSelectionnees = casesParSemaine[debutSemaine] || [];
        const casesColonne = heures.map((h) => `${jour}-${h}`);

        const toutesSelectionnees = casesColonne.every((c) => casesSelectionnees.includes(c));
        const toutesDeselectionnees = casesColonne.every((c) => !casesSelectionnees.includes(c));

        if (toutesDeselectionnees) {
          setCasesParSemaine({
            ...casesParSemaine,
            [debutSemaine]: [...casesSelectionnees, ...casesColonne],
          });
        } else if (toutesSelectionnees) {
          setCasesParSemaine({
            ...casesParSemaine,
            [debutSemaine]: casesSelectionnees.filter((c) => !casesColonne.includes(c)),
          });
        } else {
          const nouvellesCasesSelectionnees = casesSelectionnees.filter((c) => !casesColonne.includes(c)).concat(casesColonne);
          setCasesParSemaine({
            ...casesParSemaine,
            [debutSemaine]: nouvellesCasesSelectionnees,
          });
        }
        setNeedSave(true);
      }
    }

    if (access) {
      casesParSemaineRef.current = casesParSemaine;
      joursRef.current = jours;
      heuresRef.current = heures;
      sondageRef.current = sondage;
      debouncedSave();
    }

  };

  const handleClickHeure = (heure, index) => {
    let access = true;

    // si il y a un hash et que je ne suis pas temporairement débloqué
    if (userOptions.hasOwnProperty('hash') && !tempUnLock) {
      // Faire quelque chose si 'hash' existe
      if (userOptions['hash'] !== "") {
        access = false;
      }

    }
    if (editMode) {
      setEditingRowIndex(index);
      setTimeout(() => {
        inputHeureRef.current && inputHeureRef.current.focus();
      }, 0);
      access = true;
    } else {
      if (userFirst === currentUser && access && !options["1 seul choix par user"] && !options["1 seul choix par case"]) {
        setRelaodUserDispo(true);
        const semaine = getDebutSemaine(date);
        const casesSelectionnees = casesParSemaine[semaine] || [];
        const casesLigne = jours.map((j) => `${j}-${heure}`);

        const toutesSelectionnees = casesLigne.every((c) => casesSelectionnees.includes(c));
        const toutesDeselectionnees = casesLigne.every((c) => !casesSelectionnees.includes(c));

        if (toutesDeselectionnees) {
          setCasesParSemaine({
            ...casesParSemaine,
            [semaine]: [...casesSelectionnees, ...casesLigne],
          });
        } else if (toutesSelectionnees) {
          setCasesParSemaine({
            ...casesParSemaine,
            [semaine]: casesSelectionnees.filter((c) => !casesLigne.includes(c)),
          });
        } else {
          const nouvellesCasesSelectionnees = casesSelectionnees.filter((c) => !casesLigne.includes(c)).concat(casesLigne);
          setCasesParSemaine({
            ...casesParSemaine,
            [semaine]: nouvellesCasesSelectionnees,
          });
        }
        setNeedSave(true);

      }
    }

    if (access) {
      casesParSemaineRef.current = casesParSemaine;
      joursRef.current = jours;
      heuresRef.current = heures;
      sondageRef.current = sondage;
      debouncedSave();
    }
  };

  function generateHash() {
    const now = new Date();
    const timestamp = now.getTime();
    const hash = timestamp.toString(36);
    return hash;
  }

  const memoizedCalendarName = useMemo(() => {
    if (isInit) {

      return [];
    }
    return calendarName;
  }, [calendarName, isInit]);

  const memoizedColor = useMemo(() => {
    if (isInit) {
      return [];
    }
    return currentColor;
  }, [currentColor, isInit]);

  const memoizedUserDispo = useMemo(() => {
    if (isInit) {
      return [];
    }
    heures.forEach((h) => {
      jours.forEach((j) => {
        getAvailableUsers(j, h, calendriers, currentUser);
      });
    });
    return userDispo;

  }, [userDispo, isInit, date, currentUser, currentColor]);



  const fetchData = async (calValue) => {
    return fetch('/api/getAllCalendarByID/' + calValue + '?XDEBUG_SESSION=VSCODE', {
      method: 'POST',
      body: JSON.stringify({ name: calendarName, calValue: calValue }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {



        if (data[0]) {






          data[0].forEach(function (element) {

            if (element.hasOwnProperty('j')) {
              setJours(element.j);
              if (element.j.length === 0) {
                element.j.push("lun.");
              }

            }

            if (element.hasOwnProperty('h')) {
              setHeures(element.h);

            }

            if (element.hasOwnProperty('calendarName')) {
              setCalendarName(element.calendarName);

            }

            if (element.hasOwnProperty('options')) {
              setOptions(element.options);

            }

            if (element.hasOwnProperty('sondage')) {
              if (element.sondage === "1") {
                setSondage(true);
                setDate("sondage");
              } else {
                setSondage(false);
                setDate(getMostRecentWeek(data));
              }
            }

          });





          setCalendriers(data[1]);
          setCalHash(calValue);
          console.log("C api");
          updateList(data[1].map((item, index) => {
            if (!item.color) {
              item.color = '#44FF46';
            }
            if (index === 0) {
              setAdminUser(item.user);
            }
            return { user: item.user, color: item.color, options: item.options };
          }));

          // Mise à jour de userDispo

          data[0][1].h.forEach((h) => {
            data[0][0].j.forEach((j) => {
              getAvailableUsers(j, h, data[1], currentUser);
            });
          });

          if (data[1].length < 1) {

            setIsSave(false);
          } else {
            setIsSave(true);
          }

          setUserDispo(userDispo);

        } else {
          const newUrl = `${window.location.origin}`;
          setCalHash(null);
          window.history.pushState({}, '', newUrl);

          const storedCalendars = JSON.parse(localStorage.getItem('visitedCalendars'));
          const updatedCalendars = storedCalendars.filter(
            (calendar) => calendar.number !== calValue
          );

          localStorage.setItem('visitedCalendars', JSON.stringify(updatedCalendars));
          addDebugMessage("le Dooligth " + calValue + " semble ne pas avoir été finalisé ou enregistré, il faut le recréer");

        }

        setInit(false);

      })
      .catch(error => {
        addDebugMessage("le Doolight N° " + calValue + " n'est pas disponible pour le moment");
      });
  };


  useEffect(() => {


    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const calValue = urlParams.get('cal');

      if (calValue) {

        document.getElementById("indexhtml").innerHTML = "";

        setInputVisible(false);
        const data = { "id": calValue };
        Object.entries(casesParSemaine).forEach(([semaine, casesSelectionnees]) => {
          data[semaine] = casesSelectionnees;
        });



        // Utiliser la valeur stockée par useMemo au lieu de l'état calendriers
        if (memoizedColor.length > 0 && calHash === calValue) {//

          console.log(calendriers);
          updateList(calendriers.map((item) => {
            if (!item.color) {
              item.color = memoizedColor;
            }
            return { user: item.user, color: item.color, options: item.options };
          }));


          setCurrentColor(memoizedColor)
          setCalendarName(memoizedCalendarName)
          setCalHash(calValue);
          setInputVisible(false);



          //si nouveau calendrier il faut attendre un user avant d'appeler API sinon ça sert a rien
        }


        if (memoizedCalendarName.length > 0 && calHash === calValue) {
          setCalendarName(memoizedCalendarName);
        }

        if (relaodUserDispo) {
          heures.forEach((h) => {
            jours.forEach((j) => {
              getAvailableUsers(j, h, calendriers, currentUser);
            });
          });




          setUserDispo(userDispo);
          setRelaodUserDispo(false);
        } else {

          setUserDispo(memoizedUserDispo);
        }


        if (isInit) {
          var test = await fetchData(calValue);

        }
        // Le reste du code...




        let nombre = jours.length;


        if (window.innerWidth > 1000) {
          nombre = 11
        } else if (window.innerWidth > 700) {
          nombre = 7
        } else if (window.innerWidth > 400) {
          nombre = 2
        } else {
          nombre = 2
        }

        setMorceauxJours(diviserEnSousTableaux(jours, nombre));

        setInputVisible(false);

      } else {
        setInputVisible(true);
      }

      const preventScroll = (e) => {
        if (isTouchActive.current) {
          //e.preventDefault();
        }
      };

      document.addEventListener('touchmove', preventScroll, { passive: false });

      let max = 2;
      let count = 0;

      const countInit = {};

      // Parcourir chaque utilisateur
      for (let user of calendriers) {
        // Parcourir chaque semaine pour cet utilisateur
        if (currentUser === user.user) {
          if (Object.keys(casesParSemaine).length !== 0) {
            user.weeks = [];
            Object.entries(casesParSemaine).forEach(([semaine, casesSelectionnees]) => {
              if(casesSelectionnees === undefined){
                casesSelectionnees=[]
              }
              user.weeks.push({
                week: semaine,
                selectedCases: casesSelectionnees
              });
            });
          }


        }

        for (let week of user.weeks) {
          // Parcourir chaque case sélectionnée pour cette semaine
          if (week.selectedCases) {
            for (let selectedCase of week.selectedCases) {
              // Créer une clé unique pour la semaine et la case sélectionnée
              let key = week.week + "-" + selectedCase;

              // Si cette clé n'existe pas encore dans l'objet count, l'ajouter avec une valeur de 1
              // Sinon, incrémenter la valeur de cette clé de 1
              if (!countInit[key]) {
                countInit[key] = 1;
              } else {
                countInit[key]++;
              }
            }
          }

        }


      }

      // Trouver le nombre maximum de personnes qui ont sélectionné la même case
      let maxCount = Math.max(...Object.values(countInit));

      if (maxCount > max) {
        max = maxCount;
      }

      for (let key in userDispo) {
        count = userDispo[key].length;
        if (count > max) {
          max = count;
        }
      }








      setMaxUser(max);

      return () => {
        document.removeEventListener('touchmove', preventScroll);






      };


    })();

    if (editingColumnIndex !== -1 && inputRef.current) {
      inputRef.current.focus();
    }
    if (editingRowIndex !== -1 && inputHeureRef.current) {
      inputHeureRef.current.focus();
    }

    casesParSemaineRef.current = casesParSemaine;
    joursRef.current = jours;
    heuresRef.current = heures;
    sondageRef.current = sondage;

  }, [//updateList,
    casesParSemaine, calHash, sondage,//memoizedUserDispo, //memoizedCalendarName,//memoizedColor
    , currentUser, currentColor, jours, heures, editingColumnIndex, casesParSemaine]);



  function handleDoubleClick() {

  }

  const handleDeleteHoraire = (horaire) => {
    if (heures.length <= 1) {
      addDebugMessage(`Vous devez au moins mettre une ligne de choix`);

    }
    setHeures(heures.filter(h => h !== horaire));

  };

  const handleDeleteJour = (jour) => {

    if (jours.length < 2) {
      addDebugMessage(`Vous devez garder au moins une colonne`);
    } else {
      setJours(jours.filter(j => j !== jour));

    }



  };

  const resetAction = () => {
    currentAction.current = null;
  };

  const handleAddPlage = () => {
    const nouvellePlage = document.getElementById('nouvelle-plage').value;
    if (nouvellePlage !== '') {
      setHeures([...heures, nouvellePlage]);
      document.getElementById('nouvelle-plage').value = '';
    }
  };

  const handleAddJour = () => {
    const nouvellePlage = document.getElementById('nouvelle-jour').value;
    if (nouvellePlage !== '') {
      const nouveauJour = nouvellePlage.toLowerCase(); // convertir en minuscules pour comparer

      // Vérifier si le jour est déjà présent dans le tableau
      if (!jours.map(j => j.toLowerCase()).includes(nouveauJour)) {
        setJours([...jours, nouvellePlage]);
        document.getElementById('nouvelle-jour').value = '';
      } else {
        addDebugMessage(`Le jour "${nouvellePlage}" est déjà présent dans le tableau.`);
      }
    }
  };


  function save(needSave, Color, lesUserOptions, forceNewUser, newUser, autosave) {



    if ((userFirst === currentUser && !isAnimating) || forceNewUser) {


      setTimeout(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const calValue = urlParams.get('cal');

        let theuser = currentUser;


        if (theuser === "") {
          if (newUser) {
            theuser = newUser.user;
          }

        }

        let j;
        let h;
        let sondage2;

        if (autosave) {
          j = joursRef.current;
          h = heuresRef.current;
          sondage2 = sondageRef.current;
        } else {
          j = jours;
          h = heures;
          sondage2 = sondage;
        }

        if (jours && heures && Color && theuser && calValue && needSave) {
          setIsAnimating(true);


          let tableauVide = [];
          let tableauVide2 = [];


          const dataall = {
            j: j,
            h: h,
            sondage: sondage2,
            user: theuser,
            userColor: Color,
            userOptions: lesUserOptions,
            calName: encodeURIComponent(calendarName),
            calHash: calValue,
            weeks: tableauVide
          };

          let caseSemaine;
          if (autosave) {
            caseSemaine = casesParSemaineRef.current;
          } else {
            caseSemaine = casesParSemaine;
          }


          Object.entries(caseSemaine).forEach(([semaine, casesSelectionnees]) => {
            if(casesSelectionnees === undefined){
              casesSelectionnees=[]
            }
            dataall.weeks.push({
              week: semaine,
              selectedCases: casesSelectionnees
            });
          });

          if (dataall.weeks.length < 1) {
            dataall.weeks.push({
              week: getDebutSemaine(date),
              selectedCases: tableauVide2
            });
          }

          if (editMode) {
            dataall.options = options;
            fetch('/api/postCalendarOptions/' + calValue + '?XDEBUG_SESSION=VSCODE', {
              method: 'POST',
              body: JSON.stringify(dataall),
              headers: {
                'Content-Type': 'application/json'
              }
            })
              .then(response => response.json())
              .then(data => {
                if (typeof data === 'object' && data !== null) {
                  if (data.hasOwnProperty('options')) {
                    setOptions(data.options);
                  }
                  setIsSave(true);
                }
              })
              .catch(error => {
                addDebugMessage("le Doolight N° " + calValue + " ne peut pas s'enregistrer pour le moment cette erreur est inhabituelle" + error);
              });
          }

          fetch('/api/postCalendar/' + calValue + '?XDEBUG_SESSION=VSCODE', {
            method: 'POST',
            body: JSON.stringify(dataall),
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(response => response.json())
            .then(data => {

              if (Object.keys(data).length > 2) {
                if (typeof data === 'object' && data !== null) {




                  if (data.hasOwnProperty('options')) {
                    setOptions(data.options);
                  }




                  setIsSave(true);
                }

                setIsSave(true);
              } else {
                setIsSave(false);
              }
              setNeedSave(false);
            })
            .catch(error => {
              addDebugMessage("le Doolight N° " + calValue + " ne peut pas s'enregistrer pour le moment cette erreur est inhabituelle" + error);
            });
          setTimeout(() => {
            setIsAnimating(false);
          }, 2000);
        } else {

        }
      }, 100); //ne pas changer cette valeur


    }

    cancelSave();

  }

  const handleEnregistrer = () => {
    save(true, currentColor, userOptions, false, null, false);
  };

  function getAvailableUsers(j, h, lescalendriers, currentUser) {

    const availableUsers = [];
    const availableUserActif = [];

    lescalendriers.forEach(calendrier => {
      const { user, color, weeks } = calendrier;
      weeks.forEach(weekData => {
        const { week, selectedCases } = weekData;
        if (selectedCases !== undefined) {
          if (week === getDebutSemaine(date) && selectedCases.includes(`${j}-${h}`)) {
            if (user !== currentUser && !availableUsers.includes(user)) {
              availableUsers.push({ user, color }); // Ajouter un objet {user, color} au lieu de user seul
            }
          }
        }
      });
    })

    if (getCases().includes(j + '-' + h)) {

      availableUserActif.push({ user: currentUser, color: currentColor }); // Ajouter un objet {user, color} avec une couleur vide pour l'utilisateur actif
    }

    let box = availableUsers.concat(availableUserActif);

    const cellId = `${j}-${h}`;
    userDispo[cellId] = box;
    return box
  }

  function getAvailableOtherUsers(j, h, lescalendriers, currentUser) {

    const availableUsers = [];


    lescalendriers.forEach(calendrier => {
      const { user, color, weeks } = calendrier;
      weeks.forEach(weekData => {
        const { week, selectedCases } = weekData;
        if (selectedCases !== undefined) {
          if (week === getDebutSemaine(date) && selectedCases.includes(`${j}-${h}`)) {
            if (user !== currentUser && !availableUsers.includes(user)) {
              availableUsers.push({ user, color }); // Ajouter un objet {user, color} au lieu de user seul
            }
          }
        }
      });
    })

    let box = availableUsers;

    const cellId = `${j}-${h}`;
    userDispo[cellId] = box;
    return box
  }


  function getMostRecentWeek(data) {
    let mostRecentWeek = new Date();
    mostRecentWeek.setHours(0, 0, 0, 0);
    let found = false;

    if (data[1] !== undefined) {


      data[1].forEach(user => {
        user.weeks.forEach(week => {
          if (week.selectedCases && week.selectedCases.length > 0) {
            let weekDate = new Date(week.week.split('/').reverse().join('-'));



            if (!found || weekDate < mostRecentWeek) {
              if (isNaN(weekDate.getTime())) {
                weekDate = new Date();
              }
              mostRecentWeek = weekDate;
              found = true;
            }
          }
        });
      });

    }

    // return found ? mostRecentWeek : new Date();
    // Check if mostRecentWeek is in the past. If it is, return today's date instead
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Set hours, minutes, seconds and milliseconds to zero to only compare date
    if (mostRecentWeek < today) {
      return today;
    }

    return found ? mostRecentWeek : new Date();
  }

  const handleToggle = () => {
    if (!sondage) {
      setDate("sondage");
    } else {
      setDate(new Date());
    }
    setSondage(!sondage);
  }


  return (<div><Banner image={options["Image de fond"]} doodle={options["Supprimer le titre doolight"]} />
    {console.log("rendu")}
    {inputVisible ? (
      <div className="card mb-0">
        <div className="card-body bg-light mb-0">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h2 className="card-title">Quel est l'évènement à Programmer 🗓️ ?</h2>
            <form onSubmit={handleCalendarNameSubmit} >
              <div className="d-flex align-items-center justify-content-between mt-3" >
                <input
                  type="text"
                  value={decodeURIComponent(calendarName)}
                  name="calendrierName"
                  onChange={handleCalendarNameChange}
                  placeholder="Entrez le nom de l'évènement"
                  className="form-control col-sm-12 col-md-6"
                />
                <button type="submit" className="btn btn-primary">
                  Valider
                </button>
              </div>
            </form>
          </div>
          <img src='img/exemple.png' width='100%' alt='dooLight' style={{ marginTop: '30px' }} ></img>
        </div>

      </div>

    ) : (

      <div className="container-fluid p-0 d-flex align-items-center justify-content-center" style={{ marginTop: '-55px', height: '55px', backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <h1 style={{ color: 'white', opacity: 1 }}>{decodeURIComponent(calendarName)}</h1>
      </div>



    )}

    {currentUser ? (
      <>
        <div className="container" style={{ marginTop: '30px' }}>
        {options["Description"] && options["Description"].trim() !== "" && (
        <div className="row" style={{ background: "rgb(208, 226, 255)", marginBottom: "20px", padding: "10px", border: "1px solid color(srgb 0.8718 0.8868 0.9038)" }}>
                  <img src="img/premium.png" alt="Icône" style={{ width: "30px", padding: "0px" }} />
                 
                    <div className="description-display" style={{ whiteSpace: 'pre-wrap', textAlign: 'left', marginTop: "-22px", marginLeft: "22px" }}>
                      {options["Description"]}
                    </div>
                 
                </div>
              )}
          {!sondage ?
            (

             
               

                <div className="row align-items-center">

                  <div className="col text-center">
                    <button onClick={decrementerSemaine} className="btn btn-primary btn-lg">&lt;</button>
                  </div>
                  <div className="col text-center">

                    {getDebutSemaine(date).split('/').slice(0, 2).join('/')} - {getFinSemaine(date).split('/').slice(0, 2).join('/') // {getDebutSemaine(date)} - {getFinSemaine(date)}
                    }
                  </div>
                  <div className="col text-center">
                    <button onClick={incrementerSemaine} className="btn btn-primary btn-lg">&gt;</button>
                  </div>
                </div>) : null
          }

          <div className="row align-items-center" >

            <div className="col text-center">
              {editMode ? null : (
                userFirst === currentUser ? (
                  sondage ?
                    <div className='mt-2'><span style={{ fontSize: '1.5rem' }} >{userFirst}</span> , faites vos choix sur ce sondage</div>
                    :
                    <div className='mt-2'><span style={{ fontSize: '1.5rem' }} >{userFirst}</span> , saisissez vos dispos sur le calendrier</div>
                ) : (
                  <div>Voici les choix de {currentUser}</div>
                )
              )}

            </div>
          </div>
        </div>
        {editMode ? <div>  {sondage ? ('En mode sondage il est préférable de customiser les noms de vos colonnes') :
          <span>Par defaut le calendrier est infini <br /> (les dates sont rajoutées automatiquement quand la colonne commence par lun. mar. mer. ect...)<br /> Vous pouvez passer en mode sondage pour limiter les choix</span>}
          Mode Sondage <ToggleSwitch id="sondage" initialMode={sondage} onToggle={handleToggle} /> </div> : null}

        {morceauxJours.map((morceauJours, indexMorceau) => (
          <div key={`div-morceau-${indexMorceau}`}>

            <table
              className="table table-bordered mt-3"
              key={`morceau-${indexMorceau}`}
            >
              <thead>
                <tr>
                  <th scope="col" style={{ width: "100px" }}>

                    {(userFirst === currentUser && currentUser === admintUser && ((userOptions.hasOwnProperty('hash') && tempUnLock && userOptions['hash'] !== "") || (userOptions.hasOwnProperty('hash') && !tempUnLock && userOptions['hash'] === "") || (!userOptions.hasOwnProperty('hash')))) ? (

                      <button onClick={handleEditModeToggle}>
                        {editMode ? "Aperçu" : "Editer"}
                      </button>) : ('')
                    }

                  </th>
                  {morceauJours.map((j, index) => {
                    const globalIndex = indexMorceau * morceauJours.length + index;
                    let datedebu = getDebutSemaine(date);
                    let nofound = true;
                    const cellDate = new Date();
                    if (datedebu !== "sondage") {


                      const parts = datedebu.split('/');

                      cellDate.setFullYear(parts[2], parts[1] - 1, parts[0]);
                      cellDate.setDate(cellDate.getDate() + jours.indexOf(j));



                      let count = 0;
                      let isCurrentDay = false;
                      while (nofound) {
                        isCurrentDay = cellDate.toLocaleString('fr-FR', { weekday: 'short' }).slice(0, 3).toLowerCase() === j.slice(0, 3).toLowerCase();

                        if (!isCurrentDay) {
                          // Masquer la colonne si la condition est fausse
                          cellDate.setDate(cellDate.getDate() + 1);
                          count++;

                        } else {
                          nofound = false;
                          break;
                        }

                        if (count > 7) {
                          break;
                        }
                      }
                    }
                    return (
                      <th scope="col" className="table-primary" key={j} onClick={() => handleColumnClick(j, globalIndex)}>
                        {editingColumnIndex === globalIndex ? (
                          <input
                            ref={inputRef}
                            style={{
                              position: "absolute",
                              zIndex: 9999,
                              marginTop: '-30px',
                              marginLeft: '-100px',
                            }}
                            type="text"
                            defaultValue={j}
                            onBlur={(e) => {
                              let newDays = [...jours];
                              newDays[globalIndex] = e.target.value;
                              setJours(newDays);

                              setEditingColumnIndex(-1);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                          />
                        ) : (
                          <React.Fragment>
                            {j}
                            {nofound ? (null) : ((sondage ? '' : cellDate.toLocaleString('fr-FR', { day: 'numeric', month: 'long' })))}
                            {!editMode ? '' : <FaTimes className="delete-icon" size={25} onClick={(e) => { handleDeleteJour(j); e.stopPropagation(); }} />}
                          </React.Fragment>
                        )}
                      </th>);





                  })}

                </tr>
              </thead>
              <tbody>
                {heures.map((h, index) => (
                  <tr key={h} >
                    <th onClick={() => handleClickHeure(h, index)} scope="row">
                      {editingRowIndex === index ? (
                        <input
                          ref={inputHeureRef}
                          style={{
                            position: "absolute",
                            zIndex: 9999,
                            marginTop: '0px',
                            marginLeft: '-40px',
                          }}
                          type="text"
                          defaultValue={h}
                          onBlur={(e) => {
                            let newHours = [...heures];
                            newHours[index] = e.target.value;
                            setHeures(newHours);

                            setEditingRowIndex(-1);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.target.blur();
                            }
                          }}
                        />
                      ) : (
                        <React.Fragment>
                          {h}
                          {!editMode ? '' : <FaTimes size={25} className="delete-icon" onClick={(e) => { handleDeleteHoraire(h); e.stopPropagation(); }} />}
                        </React.Fragment>
                      )}

                    </th>
                    {morceauJours.map((j) => (
                      <td
                        key={`${j}-${h}`}
                        data-cell-id={`${j}-${h}`}
                        onMouseDown={(e) => {
                          if (!e.target.closest('.showUser')) {
                            handleMouseDown(j, h);
                            handleMouseOver(j, h);
                          }
                        }} // Modification
                        onMouseUp={() => { handleMouseUp(); resetAction(); }}
                        onMouseOver={() => handleMouseOver(j, h)}
                        onDoubleClick={handleDoubleClick}
                        onTouchStart={(e) => { handleTouchStart(e, j, h); }} // Modification
                        onTouchEnd={(e) => { handleTouchEnd(e); resetAction(); }}
                        onTouchMove={(e) => handleTouchMove(e)}
                        className={getCases().includes(`${j}-${h}`) ? '' : ''}

                        style={{
                          position: 'relative',
                          cursor: userFirst === currentUser
                            ? (
                              userOptions.hasOwnProperty('hash') && !tempUnLock && userOptions['hash'] !== ""
                                ? 'no-drop'
                                : 'copy'
                            )
                            : 'no-drop',
                            backgroundColor: getCases().includes(`${j}-${h}`) && !options["Réponse anonyme"] ? `${currentColor}22` : '',
                        }}
                      ><div>
                          <UserDispo users={activeCell && activeCell.jour === j && activeCell.heure === h ? getAvailableUsers(j, h, calendriers, currentUser) : userDispo && userDispo[`${j}-${h}`] ? userDispo[`${j}-${h}`] : []} 
                          activeUser={currentUser} 
                          maxUser={maxUser}
                          anonymous={options["Réponse anonyme"]}
                           />
                           {console.log(options["Réponse anonyme"])}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}

              </tbody>
            </table>


            <div>
              <button className={`btn btn-primary ${isAnimating ? "btn-animated" : ""} m-2`} onClick={handleEnregistrer}>
                Enregistrer
              </button>
              {!isSave ? <>
                {!isAnimating ? handleEnregistrer() : null}

              </> : null}



            </div>
          </div>
        ))}
        {editMode ? (
          <div className="card mb-3">
            <div className="card-body bg-light">
              <h5 className="card-title">Nouvelle Colonne (jour):</h5>
              <div className="d-flex align-items-center justify-content-between">
                <input
                  className="form-control mb-1"
                  id="nouvelle-jour"
                  type="text"
                  placeholder='Lun. ou Mar. ou Noel ou PainBall'
                />
                <FaPlus
                  style={{ color: "green" }}
                  size={42}
                  onClick={(e) => {
                    handleAddJour();
                    e.stopPropagation();
                  }}
                />
              </div>
            </div>
            <HeuresComponent
              heures={heures}
              setHeures={setHeures}
              editMode={editMode}
            />
            <OptionsManager
              initialOptions={options}
              onOptionsChange={handleOptionsChange}
            />
            <div className="card-body bg-light">
              <button className={`btn btn-primary ${isAnimating ? "btn-animated" : ""} m-2`} onClick={handleEnregistrer}>
                Enregistrer
              </button>
            </div>
          </div>



        ) : (
          ""
        )}

      </>
    ) : (
      ""
    )}

    {calendarName && calHash && !editMode ? (<User onUserNameUpdate={handleUserNameUpdate} onUserSet={handleUserSet} userList={listUser} userFirst={userFirst} unlock={handlelock} unlockValue={tempUnLock} hashDoodle={calHash} />) : ('')}
    {isSave ? (
      <div>
        {calendarName && calHash && listUser.length > 0 ? (<Share link={window.location} calName={calendarName}></Share>) : ('')}
      </div>
    ) : (
      ''
    )}


    {((calHash === "" || calHash === null) || ((calHash !== "" || calHash === null) && currentUser)) ? (
      <CalendarList currentCalendarNumber={calHash} currentCalendarName={calendarName} />
    ) : (<div></div>)}
    {<LogError message={debugMessages} />}
  </div>);



}

export default Calendrier;