import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import LogError from './LogError';


function HeuresComponent({ heures, setHeures, editMode }) {
  const [nouvelleHeure, setNouvelleHeure] = useState('');
  const [tempsAjout, setTempsAjout] = useState('30 minutes');
  const [debugMessages, setDebugMessages] = useState('');

  const handleAjoutHeure = () => {
    if (editMode && nouvelleHeure.trim() !== '') {
      if (!heures.includes(nouvelleHeure)) {
        setHeures([...heures, nouvelleHeure]);
        setNouvelleHeure('');
      } else {
        setDebugMessages('Cette heure existe déjà dans le tableau.');
      }
    }
  };

  const handleAjoutTemps = () => {
    const tempsMap = {
      '30 minutes': 30,
      '15 minutes': 15,
      '1 heure': 60,
    };
    const selectedTemps = tempsMap[tempsAjout];
    const debut = 8;
    const fin = 22;
    const plageHoraires = [];
    for (let i = debut; i <= fin; i++) {
      for (let j = 0; j < 60; j += selectedTemps) {
        const heure = `${i < 10 ? '0' : ''}${i}:${j < 10 ? '0' : ''}${j}`;
        if (!heures.includes(heure)) {
          plageHoraires.push(heure);
        }
      }
    }
    const nouveauHeures = [...heures, ...plageHoraires];
    nouveauHeures.sort((heureA, heureB) => {
      const heureAParts = heureA.split(':');
      const heureBParts = heureB.split(':');
      const heureAEnMinutes = parseInt(heureAParts[0], 10) * 60 + parseInt(heureAParts[1], 10);
      const heureBEnMinutes = parseInt(heureBParts[0], 10) * 60 + parseInt(heureBParts[1], 10);
      return heureAEnMinutes - heureBEnMinutes;
    });
    setHeures(nouveauHeures);
  };

  return (
    <div>
      {editMode && (
        <div>
          <div className="card mb-3">
            <div className="card-body bg-light">
              <h5 className="card-title">Nouvelle Ligne (heure)</h5>
              <div className="d-flex align-items-center justify-content-between">
                <input
                  className="form-control mb-3"
                  type="text"
                  value={nouvelleHeure}
                  placeholder='8h ou Avions ou Saucisse...'
                  onChange={(e) => setNouvelleHeure(e.target.value)}
                />
                <FaPlus
                  size={42}
                  style={{ color: 'green' }}
                  onClick={(e) => {
                    handleAjoutHeure();
                    e.stopPropagation();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body bg-light">
              <h5 className="card-title"> Ajouter plusieurs horraires tous les </h5>
              <div className="d-flex align-items-center justify-content-between">
                <select
                  className='form-control mb-3'
                  value={tempsAjout}
                  onChange={(e) => setTempsAjout(e.target.value)}
                >
                  <option value="30 minutes">30 minutes</option>
                  <option value="15 minutes">15 minutes</option>
                  <option value="1 heure">1 heure</option>
                </select>
                <button className="btn btn-primary mb-3" onClick={handleAjoutTemps}>Ajouter la plage horaire</button>
              </div>
            </div>
          </div>

         
        </div>
      )}
  {<LogError message={debugMessages} />}
    </div>
  );
}

export default HeuresComponent;