import { useState, useEffect } from 'react';

function LogError({ message }) {
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    if(message!==""){
    setErrorMessages(messages => {
      const newMessages = [...messages];
      const lastMessage = newMessages[newMessages.length - 1];
      if (lastMessage && lastMessage.message === message) {
        lastMessage.count++;
      } else {
        if(message.length>0){
          newMessages.push({ message, count: 1 });
        }
        
      }
      return newMessages;
    });
  }
  }, [message]);

  // Afficher les messages d'erreur
  return (
    <>
      {errorMessages.length > 0 && (
        <div className="position-fixed top-0 start-0 w-100 p-3" style={{ backgroundColor: "rgba(255, 0, 0, 0.5)" ,zIndex:1000}}>
          <div className="container">
            <ul className="list-group">
              {errorMessages.map((errorMessage, index) => (
                <li key={index} className="list-group-item">
                  <span dangerouslySetInnerHTML={{__html: errorMessage.message}} /> {errorMessage.count > 1 ? `(x${errorMessage.count})` : ''}
                </li>
              ))}
            </ul>
            <button onClick={() => setErrorMessages([])} className='btn btn-warning mt-3'>OK</button>
          </div>
        </div>
      )}
    </>
  );
}

export default LogError;