import React from 'react';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      randomImage: Math.floor(Math.random() * 11) + 1,
    };
  }

  componentDidMount() {
    const { image } = this.props;
    if (image) {
      this.setState({ randomImage: null });
    }
  }


  isValidImage(url) {
    const img = new Image();
    img.src = url;
    return img.complete && img.naturalWidth !== 0;
  }

  render() {


    const { randomImage } = this.state;
    const { image } = this.props;
    const { doodle } = this.props;

    let backgroundImage;
    if (image && this.isValidImage(image)) {
      backgroundImage = `url(${image})`;
    } else {
      backgroundImage = `url(img/${randomImage}.jpg)`;
    }

    let showDooLight = true;
    if (typeof doodle === 'boolean' && doodle) {
      showDooLight = false;
    }

    return (
      <div className="banner-container">
        <div
          className="banner-image"
          style={{ backgroundImage }}
        ></div>
       
           {showDooLight && (
          <div className="banner-text text-center display-1 font-weight-bold">
            <img
              style={{ position: 'absolute', top: '10px', left: '-40px' }}
              width="30px"
              src="img/icon.png"
              alt="Logo DooLight"
            />
            DooLight
          </div>
        )}
        </div>
     
    );
  }
}

export default Banner;
