import React, { useState } from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ id, initialMode, onToggle }) => {  // Ajoutez 'id' ici
  return (
    <div className="toggle-switch">
      <input 
        type="checkbox" 
        className="toggle-switch-checkbox" 
        id={id}  // Et utilisez-le ici
        checked={initialMode}
        onChange={onToggle}
      />
      <label className="toggle-switch-label" htmlFor={id}>  
        <span className={`toggle-switch-inner ${initialMode ? 'toggle-switch-checked' : ''}`} />
        <span className={`toggle-switch-switch ${initialMode ? 'toggle-switch-checked' : ''}`} />
      </label>
    </div>
  );
}

export default ToggleSwitch;